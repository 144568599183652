<template>
  <div class="w-100 h-100 overflow-auto">
    <d-remote-monitoring-templates-add
      @on-create="onCreateHandler"
      @on-validation-error="onOnValidationErrorHandler"
    />
  </div>
</template>

<script>
import { showValidationErrorMessage } from '@/helpers/messages';

export default {
  name: 'RemoteMonitoringTemplateAdd',
  methods: {
    onCreateHandler() {
      this.$router.push('/remote-monitoring/templates/');
    },
    onOnValidationErrorHandler() {
      showValidationErrorMessage();
    },
  },
};
</script>
